$max-img-width: 600px;
$max-img-height: 400px;

.gallery img {
  // max-width: 100%;
  max-height: 96%;
  vertical-align: top;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 1%;
  width: 100%;
  position: relative;
  justify-content: center;
  min-height: 500px;
  border-radius: 4px;

  // @media screen and (min-width: $max-img-width) {
  //   padding-top: $max-img-height;
  // }
}

.gallery__item {
  display: flex;
  width: 19%;
  max-height: 30%;
  // height: 25%;
  flex-shrink: 1;
}

.gallery__img {
  border-radius: 8px;
  overflow: hidden;
  object-fit: contain;
  width: 79%;
  position: absolute;
  padding: 8px 0px 8px 8px;
  top: 0;
  left: 1%;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
}

.gallery__thumb {
  padding: 8px;
  display: block;
  width: 100%;
  & img {
    width: 100%;
    object-fit: cover;
  }
}

.gallery__selector {
  position: absolute;
  opacity: 0;
  visibility: hidden;

  &:checked {
    + .gallery__img {
      opacity: 1;
    }
    ~ .gallery__thumb > img {
      box-shadow: 0 0 2px 4px #2776d2;
    }
  }
}
